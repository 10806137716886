.react-tel-input .special-label {
  left: 0px;
  color: rgba(0, 0, 0, 0.54);
}

.react-tel-input .form-control {
  width: 100%;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
}

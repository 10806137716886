div.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20000;
  text-align: center;
}

.xa-progress {
  position: fixed;
  top: 50%;
  margin-top: -80px;
  margin-left: -80px;
}

.xa-progress-title {
  position: fixed;
  left: 50%;
  top: calc(50% + 90px);
  text-align: center;
  transform: translateX(-50%);
}
